import React from "react";
import { Button, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LoginIcon from "@mui/icons-material/Login";
import icardioLogo from "../assets/icardioLogo.svg";
import styles from "./footer.module.css";

export const Footer = () => {
  return (
    <footer className={styles.footer}>
      <Grid item xs={10} className={styles.footerContainer}>
        <div className={styles.footerHeader}>
          <Link to="/">
            <img src={icardioLogo} alt="icardioLogo" className={styles.footerLogo} />
          </Link>
          <div className={styles.footerSlogan}>
            Ai for echo has <span className={styles.sloganGradient}>Arrived.</span>
          </div>
          <div className={styles.gradientLine}></div>
        </div>
        <Grid container justifyContent="center" className={styles.footerNavWrapper}>
          <Grid item xs={12} md={8} className={styles.footerNavContainer}>
            <div className={styles.footerNavHeader}>
              <Grid item xs={4}>
                <span className={styles.footerNavHeaderItem}>Navigate</span>
              </Grid>
              <Grid item xs={4}>
                <span className={styles.footerNavHeaderItem}>Models</span>
              </Grid>
              <Grid item xs={4}>
                <span className={styles.footerNavHeaderItem}>Company</span>
              </Grid>
            </div>
            <div className={styles.footerNav}>
              <Grid item xs={4}>
                <ul className={styles.footerNavList}>
                  <Link to="/" className={styles.footerNavLink}>
                    <li className={styles.footerNavListItem}>Solutions</li>
                  </Link>
                  <Link to="/" className={styles.footerNavLink}>
                    <li className={styles.footerNavListItem}>Interpretations</li>
                  </Link>
                  <Link to="/" className={styles.footerNavLink}>
                    <li className={styles.footerNavListItem}>Data</li>
                  </Link>
                  <a href="https://www.icardio.ai/team" className={styles.footerNavLink}>
                    <li className={styles.footerNavListItem}>Team</li>
                  </a>
                </ul>
              </Grid>
              <Grid item xs={4}>
                <ul className={styles.footerNavList}>
                  <Link to="/" className={styles.footerNavLink}>
                    <li className={styles.footerNavListItem}>Segmentation</li>
                  </Link>
                  <Link to="/" className={styles.footerNavLink}>
                    <li className={styles.footerNavListItem}>Keypoint</li>
                  </Link>
                  <Link to="/" className={styles.footerNavLink}>
                    <li className={styles.footerNavListItem}>Classification</li>
                  </Link>
                  <Link to="/" className={styles.footerNavLink}>
                    <li className={styles.footerNavListItem}>Regression</li>
                  </Link>
                </ul>
              </Grid>
              <Grid item xs={4}>
                <ul className={styles.footerNavList}>
                  <a href="https://www.linkedin.com/company/19196888" className={styles.footerNavLink}>
                    <li className={styles.footerNavListItem}>
                      <LinkedInIcon className={styles.linkedinIcon} /> Linkedin
                    </li>
                  </a>
                  <a href="https://www.icardio.ai/releases" className={styles.footerNavLink}>
                    <li className={styles.footerNavListItem}>Releases</li>
                  </a>
                </ul>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={styles.contactCard}>
              <h3 className={styles.contactCardHeader}>The world's most comprehensive AI for hearts.</h3>
              <p className={styles.contactCardText}>Transform the way you read echos today.</p>
              <Link to="/">
                <Button variant="contained" className={styles.contactUsButton}>
                  Contact us
                </Button>
              </Link>
            </div>
            <div className={styles.footerLoginWrapper}>
              <Link to="/login" className={styles.footerNavLink}>
                <Button variant="outlined" className={styles.footerLoginButton}>
                  <LoginIcon className={styles.loginIcon} /> Log In
                </Button>
              </Link>
              <span className={styles.footerLoginDivider}>or</span>
              <Link to="/signup" className={styles.footerNavLink}>
                <Button variant="outlined" className={styles.footerLoginButton}>
                  Sign up
                </Button>
              </Link>
            </div>
          </Grid>
        </Grid>
        <span className={styles.copyrights}>2022 © ICARDIO.AI</span>
      </Grid>
    </footer>
  );
};
