import React, { useEffect, useState, FC } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Input,
} from "@mui/material";
import classNames from "classnames/bind";
import AddIcon from "@mui/icons-material/Add";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import { useSnackbar } from "notistack";
import { Preloader } from "../../components/preloader";
import styles from "./webhooks.module.css";
import { apiClient } from "../../api/base";
import { Webhook } from "../../api/generated_api";
import { isEditable } from "@testing-library/user-event/dist/utils";

const cx = classNames.bind(styles);

export const Webhooks: FC = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [webhooks, setWebhooks] = useState<Webhook[]>([]);
  const [editable, setEditable] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const [creating, setCreating] = useState<boolean>(false);
  const [createInputValue, setCreateInputValue] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const accessToken = window.localStorage.getItem("token");

  useEffect(() => {
    apiClient
      .listWebhooks({})
      .then((res) => {
        res?.data?.results && setWebhooks(res?.data?.results);
      })
      .catch(() => {
        enqueueSnackbar("Webhooks load failed", { variant: "error" });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleEditable = (id: string, url: string) => {
    setEditable((prevEditable: any) => {
      if (!prevEditable.includes(id)) {
        const newEditable = [];
        newEditable.push(id);
        setInputValue(url);
        return [...prevEditable, ...newEditable];
      } else {
        setIsUpdating(true);
        apiClient
          .partialUpdateWebhook({ uuid: id, webhook: { url: inputValue } })
          .then((res) => {
            setWebhooks([res?.data]);
          })
          .catch(() => {
            enqueueSnackbar("Webhook update failed", { variant: "error" });
          })
          .finally(() => {
            setIsUpdating(false);
          });
        return [];
      }
    });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleDelete = (id: string) => {
    setIsLoading(true);
    apiClient
      .destroyWebhook({ uuid: id })
      .then(() => {
        setWebhooks((prevWebhooks: any) => {
          return prevWebhooks.filter((prevWebhook: any) => prevWebhook.uuid !== id);
        });
      })
      .catch(() => {
        enqueueSnackbar("Webhook delete failed", { variant: "error" });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleTest = (id: string) => {
    setIsLoading(true);
    apiClient
      .retrieveTestWebhook({ uuid: id })
      .then(() => {
        enqueueSnackbar("Test request sent", { variant: "success" });
      })
      .catch((reason) => {
        enqueueSnackbar("Test request failed", { variant: "error" });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCreating = () => {
    setCreating((prevCreating) => !prevCreating);
  };

  const handleNewWebhookInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCreateInputValue(event.target.value);
  };

  const handleCreateNewWebhook = (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    apiClient
      .createWebhook({ webhook: { url: createInputValue } })
      .then((res) => {
        setWebhooks([res.data]);
      })
      .catch(() => {
        enqueueSnackbar("Webhook failed to add", { variant: "error", preventDuplicate: true });
      })
      .finally(() => {
        setCreating(false);
        setCreateInputValue("");
        setIsLoading(false);
      });
  };

  return (
    <Grid container justifyContent="center" className={styles.wrapper}>
      {isLoading ? (
        <Preloader />
      ) : (
        <>
          <div className={cx("controlButtons", { singleExportButtonWrapper: webhooks?.length })}>
            {!webhooks?.length && (
              <div className={styles.createButtonWrapper}>
                <Button variant="contained" className={styles.controlButton} onClick={handleCreating}>
                  {creating ? (
                    "Cancel"
                  ) : (
                    <>
                      <AddIcon className={styles.addIcon} />
                      Create a Webhook
                    </>
                  )}
                </Button>
                {creating && (
                  <form onSubmit={handleCreateNewWebhook}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="success"
                      disabled={!createInputValue}
                      className={styles.editButton}
                    >
                      Add
                    </Button>
                    <Input
                      data-cy={"webhook-input"}
                      className={styles.createWebhookInput}
                      value={createInputValue}
                      onChange={handleNewWebhookInput}
                      placeholder="Url"
                    />
                  </form>
                )}
              </div>
            )}
          </div>
          <TableContainer component={Paper} className={styles.webhookTable}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className={styles.tableCell} colSpan={4}>
                    Url
                  </TableCell>
                  <TableCell className={styles.tableCell} colSpan={4}>
                    Webhook Token
                  </TableCell>
                  <TableCell className={styles.tableCell} align="right" colSpan={4}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {webhooks?.length ? (
                  webhooks.map((row: any) => {
                    return (
                      <TableRow key={row.uuid}>
                        <TableCell align="left" className={styles.tableCell} colSpan={4} width="25%">
                          {editable.includes(row?.uuid) ? (
                            <Input fullWidth={true} value={inputValue} onChange={handleInputChange} />
                          ) : (
                            row?.url
                          )}
                        </TableCell>
                        <TableCell align="left" className={styles.tableCell} colSpan={4} width="35%">
                          {row.token || "-"}
                        </TableCell>
                        <TableCell align="right" className={styles.tableCell} colSpan={4} width="40%">
                          <Button
                            variant="contained"
                            color={editable.includes(row?.uuid) ? "success" : "info"}
                            className={styles.editButton}
                            onClick={() => handleEditable(row?.uuid, row?.url)}
                          >
                            {isUpdating ? (
                              <Preloader inner button />
                            ) : (
                              <>{editable.includes(row?.uuid) ? "Save" : "Edit"}</>
                            )}
                          </Button>

                          <Button
                            color={"info"}
                            className={styles.editButton}
                            variant="contained"
                            onClick={() => handleTest(row.uuid)}
                          >
                            Test
                          </Button>

                          <Button variant="contained" color="error" onClick={() => handleDelete(row.uuid)}>
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableCell align="center" className={styles.tableCell} colSpan={2}>
                    <FolderOutlinedIcon />
                    <div>No data</div>
                  </TableCell>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Grid>
  );
};
