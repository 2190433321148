import React from "react";
import { createTheme } from "@mui/material/styles";
// import CheckBoxIcon from "@mui/icons-material/CheckBox";
// import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

export const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#035168",
    },
    secondary: {
      main: "#f50057",
    },
    background: {
      default: "#035168",
      paper: "#035168",
    },
    text: {
      primary: "#ffffff",
    },
  },
  /*
  components: {
    MuiCheckbox: {
      defaultProps: {
        icon: <CheckBoxOutlineBlankIcon fontSize="small" />,
        checkedIcon: <CheckBoxIcon fontSize="small" />,
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "#fff",
        }
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: "#fff",
        }
      },
    },
  },
   */
});
