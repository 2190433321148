import React, { useState } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import { FormControl, Button, Grid, CircularProgress, TextField } from "@mui/material";
import { Header } from "../../components/header";
import { Footer } from "../../components/footer";
import styles from "./login.module.css";
import { apiClient } from "../../api/base";

export const Login = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [emailValue, setEmailValue] = useState<string>("");
  const [passwordValue, setPasswordValue] = useState<string>("");
  const [showEmailValidation, setShowEmailValidation] = useState<boolean>(false);
  const [showPasswordValidation, setShowPasswordValidation] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (!emailValue) {
      setShowEmailValidation(true);
      return;
    }
    if (!passwordValue) {
      setShowPasswordValidation(true);
      return;
    }
    setIsLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/token/`, {
        username: emailValue,
        password: passwordValue,
      })
      .then((res) => {
        window.localStorage.setItem("token", res?.data?.access);
        setTimeout(() => navigate("/studies"), 100);
      })
      .catch(() => {
        enqueueSnackbar("Email or password is not correct", { variant: "error", preventDuplicate: true });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailValue(event.target.value);
    if (event.target.value) {
      setShowEmailValidation(false);
    }
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordValue(event.target.value);
    if (event.target.value) {
      setShowPasswordValidation(false);
    }
  };

  return (
    <Grid container justifyContent="center" className={styles.wrapper}>
      <Header />
      <Grid item xs={12} className={styles.formWrapper}>
        <form className={styles.form} onSubmit={handleFormSubmit}>
          <h2 className={styles.formHeader}>iCardio.ai login</h2>
          <FormControl className={styles.formControl} color="info">
            <div className={styles.inputLabel}>Enter your email</div>
            <TextField
              id="email"
              fullWidth={true}
              placeholder="Email"
              onChange={handleEmailChange}
              className={styles.formInput}
            />
            <div className={styles.validationMessage}>{showEmailValidation && "Email field is required*"}</div>
          </FormControl>
          <FormControl className={styles.formControl} color="info">
            <div className={styles.inputLabel}>Enter your password</div>
            <TextField
              id="password"
              type="password"
              fullWidth={true}
              placeholder="Password"
              onChange={handlePasswordChange}
              className={styles.formInput}
            />
            <div className={styles.validationMessage}>{showPasswordValidation && "Password field is required*"}</div>
          </FormControl>
          <FormControl className={styles.formControl}>
            <Button type="submit" className={styles.confirmButton} disabled={isLoading}>
              {isLoading ? <CircularProgress className={styles.progressIcon} size={20} /> : "Login"}
            </Button>
          </FormControl>
          <div className={styles.createAccountText}>
            New here?{" "}
            <Link to="/signup" className={styles.createAccountLink}>
              Create an account
            </Link>
          </div>
        </form>
      </Grid>
      <Footer />
    </Grid>
  );
};
