import { DiameterConclusion, ReportDicom, SegmentationConclusion } from "../../api/generated_api";
import React, { useState } from "react";
import { Box, Collapse, IconButton, TableCell, TableRow, Typography } from "@mui/material";
import { Canvas } from "../segmentation/canvas";
import styles from "./reportDetails.module.css";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { formatMeasurementValue } from "./utils";
import { Segmentation } from "../segmentation/segmentation";

export const SegmentationRow = ({
  data,
  dicoms,
}: {
  data: SegmentationConclusion;
  dicoms: ReportDicom[] | undefined;
}) => {
  const [open, setOpen] = useState<boolean>(false);
  let dicom_object: ReportDicom | undefined;
  if (dicoms !== undefined) {
    dicom_object = dicoms.find((dicom) => {
      return dicom.uuid == data?.predictions?.at(0)?.dicom;
    });
  }

  const frames = data?.predictions?.map((prediction, i) => {
    return (
      prediction.lines &&
      prediction.frame?.file && (
        <React.Fragment key={i}>
          <Typography align={"center"}>{dicom_object && dicom_object.name}</Typography>
          <Segmentation data={prediction} image={prediction.frame?.file} />
        </React.Fragment>
      )
    );
  });

  return (
    <>
      <TableRow
        key={data.value}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        onClick={() => {
          if (frames?.length) {
            return setOpen((prevState) => !prevState);
          }
          return;
        }}
      >
        <TableCell className={styles.tableCell} colSpan={1}>
          <IconButton aria-label="expand row" size="small">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell id={"diameter_measurement_name"} align="left" className={styles.tableCell}>
          {data?.measurement.value || "-"}
        </TableCell>
        <TableCell id={"diameter_measurement_flag"} align="left" className={styles.tableCell}>
          {data?.flag}
        </TableCell>
        <TableCell id={"diameter_measurement_value"} align="left" className={styles.tableCell}>
          {formatMeasurementValue(data?.value)}
        </TableCell>
        <TableCell id={"diameter_measurement_reference"} align="right" className={styles.tableCell}>
          {data?.measurement.low_range && data?.measurement.high_range
            ? `${data?.measurement.low_range} - ${data.measurement?.high_range} ${data.measurement?.units}`
            : "-"}
        </TableCell>
      </TableRow>
      <TableRow style={{ backgroundColor: "black" }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                {frames}
              </Typography>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
