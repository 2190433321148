import React, { FC, useState } from "react";
import {
  Box,
  TableCell,
  TableRow,
  Typography,
  IconButton,
  Collapse,
  LinearProgressProps,
  CardMedia,
} from "@mui/material";
import classNames from "classnames/bind";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Canvas } from "../segmentation/canvas";
import styles from "./reportDetails.module.css";
import LinearProgress from "@mui/material/LinearProgress";
import { PathologyConclusion, ReportDicom } from "../../api/generated_api";

const cx = classNames.bind(styles);

interface Row {
  row: any;
}

const LinearProgressWithLabel = (props: LinearProgressProps & { value: number }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          variant="determinate"
          {...props}
          color="primary"
          className={styles.progressBar}
          sx={{
            "& .MuiLinearProgress-barColorPrimary": {
              backgroundColor: "red",
            },
          }}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography id={"pathology_value"} variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
};

const pathology_prediction_progress_bar = (value: number | null | undefined) => {
  if (value === null || value === undefined) {
    return "-";
  }

  return <LinearProgressWithLabel value={value * 100} />;
};

export const PathologyRow = ({ data, dicoms }: { data: PathologyConclusion; dicoms: ReportDicom[] }) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <TableRow
        key={data.pathology?.value}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        onClick={() => {
          return setOpen((prevState) => !prevState);
        }}
        className={cx({ tableRow: true })}
      >
        <TableCell className={styles.tableCell} colSpan={0.5}>
          <IconButton aria-label="expand row" size="small">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell id={"pathology_feature_name"} align="left" className={styles.tableCell}>
          {data?.pathology?.feature?.value || "-"}
        </TableCell>
        <TableCell id={"pathology_name"} align="left" className={styles.tableCell}>
          {data?.pathology?.value || "-"}
        </TableCell>
        <TableCell align="right" className={styles.tableCell}>
          <Box id={"pathology_score"} sx={{ width: "100%" }}>
            {pathology_prediction_progress_bar(data?.score)}
          </Box>
        </TableCell>
      </TableRow>
      <TableRow style={{ backgroundColor: "black" }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ marginTop: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                {data.dicoms.map((dicom) => {
                  const dicom_match = dicoms.find((element) => {
                    return element.uuid === dicom.uuid;
                  });
                  if (dicom_match === undefined || dicom_match.media === undefined) {
                    return null;
                  }

                  return (
                    <Box key={dicom.uuid} sx={{ marginBottom: 5 }}>
                      <Typography align={"center"}>{dicom_match && dicom_match.name}</Typography>
                      <CardMedia key={dicom_match.uuid} component={"video"} autoPlay loop src={dicom_match.media} />
                    </Box>
                  );
                })}
              </Typography>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
