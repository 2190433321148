import React, { ReactElement, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Login } from "../pages/login";

export const PrivateRoute = ({ component }: { component: ReactElement<any, any> }) => {
  const navigate = useNavigate();
  const accessToken = window.localStorage.getItem("token");
  useEffect(() => {
    if (!accessToken) {
      navigate("/login");
    }
  }, []);
  if (accessToken) {
    return <>{component}</>;
  }
  return <Login />;
};
