import { Study, StudySexEnum } from "../../api/generated_api";
import { Control, useController, UseControllerProps } from "react-hook-form";
import React, { SyntheticEvent } from "react";
import { FormControl, MenuItem, TextField } from "@mui/material";
import styles from "./studiesCreate.module.css";
import { TextFieldProps } from "@mui/material/TextField/TextField";

export type FormValues = Study;
interface FocusEvent<T = Element> extends SyntheticEvent<T> {
  relatedTarget: EventTarget | null;
  target: EventTarget & T;
}
interface InputProps {
  label: string | undefined;
}

interface Choice {
  value: string;
  label: string;
}

interface Choices {
  choices: Choice[];
}

function TextInputComponent(props: UseControllerProps<FormValues> & TextFieldProps) {
  const { field, fieldState } = useController(props);

  return (
    <FormControl className={styles.mb10}>
      <p>
        <span className={styles.title}> {props.label} </span>
      </p>
      <TextField {...field} error={fieldState.invalid} helperText={fieldState.error?.message} variant="standard" />
    </FormControl>
  );
}

function NumberInputComponent(props: UseControllerProps<FormValues> & TextFieldProps) {
  const { field, fieldState } = useController(props);
  const { label, ...field_props } = props;
  return (
    <FormControl className={styles.mb10}>
      <p>
        <span className={styles.title}> {props.label} </span>
      </p>
      <TextField
        {...field}
        type="number"
        error={fieldState.invalid}
        helperText={fieldState.error?.message}
        variant="standard"
        {...field_props}
      />
    </FormControl>
  );
}

function SelectInputComponent(props: UseControllerProps<FormValues> & TextFieldProps & Choices) {
  const { field, fieldState } = useController(props);

  return (
    <FormControl className={styles.mb10}>
      <p>
        <span className={styles.title}> {props.label} </span>
      </p>
      <TextField
        {...field}
        data-cy="sex"
        error={fieldState.invalid}
        select
        helperText={fieldState.error?.message}
        variant="standard"
      >
        {props.choices.map((option) => (
          <MenuItem data-cy={option.value} id="sex" key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  );
}

const age_rules = {
  min: { value: 18, message: "Minimum value is 18" },
  max: { value: 97, message: "Maximum value is 97" },
};
const height_rules = {
  min: { value: 36, message: "Minimum value is 36" },
  max: { value: 88, message: "Maximum value is 88" },
};
const weight_rules = {
  min: { value: 70, message: "Minimum value is 70" },
  max: { value: 550, message: "Maximum value is 550" },
};
const sex_choices = [
  {
    value: StudySexEnum.Male,
    label: "Male",
  },
  {
    value: StudySexEnum.Female,
    label: "Female",
  },
];

export function StudyInputFields(props: { control: Control<FormValues, any> }) {
  return (
    <>
      <TextInputComponent
        className={"name-input"}
        control={props.control}
        rules={{ required: true }}
        name={"name"}
        label={"Name"}
      />
      <NumberInputComponent
        control={props.control}
        rules={{
          required: true,
          ...age_rules,
        }}
        placeholder={`${age_rules.min.value} - ${age_rules.max.value}`}
        name={"age"}
        label={"Age"}
      />

      <NumberInputComponent
        control={props.control}
        rules={{
          required: true,
          ...height_rules,
        }}
        placeholder={`${height_rules.min.value} - ${height_rules.max.value}`}
        name={"height"}
        label={"Height (inches)"}
      />

      <NumberInputComponent
        control={props.control}
        rules={{
          required: true,
          ...weight_rules,
        }}
        placeholder={`${weight_rules.min.value} - ${weight_rules.max.value}`}
        name={"weight"}
        label={"Weight (lbs)"}
      />

      <SelectInputComponent
        control={props.control}
        rules={{
          required: true,
        }}
        choices={sex_choices}
        name={"sex"}
        label={"Sex"}
      />
    </>
  );
}
