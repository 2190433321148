import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useSnackbar } from "notistack";
import { FormControl, Grid, Input, InputLabel, MenuItem, Box, Button } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Canvas } from "./canvas";
import styles from "./segmentation.module.css";

export const Segmentation = () => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [reports, setReports] = useState<string>("LONG AXIS, PLAX STANDARD");
  const [perspective, setPerspective] = useState<string>("LONG AXIS, PLAX STANDARD");
  const [measurment, setMeasurment] = useState<string>("LVOT ACCEPTED");
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const handlePerspectiveChange = (event: SelectChangeEvent) => {
    setPerspective(event.target.value as string);
  };

  const handleMeasurmentChange = (event: SelectChangeEvent) => {
    setMeasurment(event.target.value as string);
  };

  return (
    <Grid container justifyContent="center" className={styles.wrapper}>
      <Grid container justifyContent="center" className={styles.filtersWrapper}>
        <Grid item xs={12}>
          <div className={styles.blockHeader}>Filters</div>
          <form>
            <Box>
              <FormControl className={styles.formControl}>
                <InputLabel id="perspective" className={styles.selectLabel}>
                  Perspective
                </InputLabel>
                <Select
                  labelId="perspective"
                  id="perspective"
                  label="Perspective"
                  value={perspective}
                  onChange={handlePerspectiveChange}
                >
                  <MenuItem value="LONG AXIS, PLAX STANDARD">LONG AXIS, PLAX STANDARD</MenuItem>
                </Select>
              </FormControl>
              <FormControl className={styles.formControl}>
                <InputLabel id="measurment" className={styles.selectLabel}>
                  Measurment collection
                </InputLabel>
                <Select
                  labelId="measurment"
                  id="measurment"
                  label="Measurment collection"
                  value={measurment}
                  onChange={handleMeasurmentChange}
                >
                  <MenuItem value="LVOT ACCEPTED">LVOT ACCEPTED</MenuItem>
                </Select>
              </FormControl>
              <FormControl className={styles.selectLabel}>
                <Button type="submit" className={styles.dicomsSubmit} variant="contained">
                  FILTER DICOMS
                </Button>
              </FormControl>
            </Box>
          </form>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item xs={6}></Grid>
        <Grid item xs={6}>
          Frames
          <div>
            <Canvas pixel_to_cm_delta_x={0} pixel_to_cm_delta_y={0} />
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};
