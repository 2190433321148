import React, { FC } from "react";
import classNames from "classnames/bind";
import { CircularProgress } from "@mui/material";
import styles from "./preloader.module.css";

const cx = classNames.bind(styles);

interface preloader {
  button?: boolean;
  inner?: boolean;
}
export const Preloader: FC<preloader> = ({ button, inner }) => (
  <div className={cx("wrapper", { inner: inner })}>
    <CircularProgress color="inherit" size={button ? 18 : 40} />
  </div>
);
