import React, { FC } from "react";
import { Grid } from "@mui/material";
import classNames from "classnames/bind";
import styles from "./home.module.css";

const cx = classNames.bind(styles);

interface sidebar {
  showBurgerMenu: boolean;
}

export const Sidebar: FC<sidebar> = ({ showBurgerMenu }) => {
  return (
    <div className={cx("sidebarWrapper", { showSidebar: showBurgerMenu })}>
      <Grid container justifyContent="center" className={styles.sidebarContent}>
        <Grid container justifyContent="center" className={styles.sidebarUpperLinks}>
          <Grid item sm={6} xs={12}>
            <a href="https://www.icardio.ai/releases" className={styles.sidebarMenuItem}>
              <div className={styles.sidebarMenuNumber}>01</div>
              <h3 className={styles.sidebarMenuItemHeader}>Releases</h3>
              <p className={styles.sidebarMenuItemText}>Read about iCardio.ai updates.</p>
            </a>
          </Grid>
          <Grid item sm={6} xs={12}>
            <a href="https://www.icardio.ai/interpretations" className={styles.sidebarMenuItem}>
              <div className={styles.sidebarMenuNumber}>02</div>
              <h3 className={styles.sidebarMenuItemHeader}>Interpretations</h3>
              <p className={styles.sidebarMenuItemText}>Get echos read by top cardiologists assisted by AI.</p>
            </a>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" className={styles.sidebarCenterLinks}>
          <Grid item sm={6} xs={12}>
            <a href="https://www.icardio.ai/data" className={styles.sidebarMenuItem}>
              <div className={styles.sidebarMenuNumber}>03</div>
              <h3 className={styles.sidebarMenuItemHeader}>Data</h3>
              <p className={styles.sidebarMenuItemText}>Use our large collection of data for your project.</p>
            </a>
          </Grid>
          <Grid item sm={6} xs={12}>
            <a href="https://www.icardio.ai/team" className={styles.sidebarMenuItem}>
              <div className={styles.sidebarMenuNumber}>04</div>
              <h3 className={styles.sidebarMenuItemHeader}>Team</h3>
              <p className={styles.sidebarMenuItemText}>Learn more about our team of experts.</p>
            </a>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" className={styles.sidebarBottomLinks}>
          <Grid item xs={12}>
            <a href="https://www.icardio.ai/contact-us" className={cx("sidebarMenuItem", "sidebarMenuContactItem")}>
              <div className={styles.sidebarMenuNumber}>05</div>
              <h3 className={styles.sidebarMenuItemHeader}>Contact us</h3>
              <p className={styles.sidebarMenuItemText}>Reach out to us and integrate AI into your pipeline today</p>
            </a>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
