import React, { FC, useState } from "react";
import {
  Box,
  TableCell,
  TableRow,
  Typography,
  IconButton,
  Collapse,
  CardContent,
  Card,
  CardMedia,
  Grid,
} from "@mui/material";
import classNames from "classnames/bind";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Canvas } from "../segmentation/canvas";
import styles from "./reportDetails.module.css";
import {
  DiameterConclusion,
  Dicom,
  Perspective,
  Quality,
  RegressionConclusion,
  ReportDicom,
} from "../../api/generated_api";
import { formatMeasurementValue } from "./utils";

const cx = classNames.bind(styles);

interface keypoint {
  coordinate_x: number;
  coordinate_y: number;
}

interface diameter {
  frame: {
    file: string;
  };
  keypoint_predictions: keypoint[];
}

interface measurement {
  highRange: number | null;
  key: number;
  lowRange: number | null;
  measurement: string;
  units: string;
  value: number;
  diameters: diameter[];
}

interface Row {
  row: measurement;
}

export const RegressionMeasurementRow = ({ data }: { data: RegressionConclusion }) => {
  return (
    <>
      <TableRow key={data.value} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell className={styles.tableCell} colSpan={1}></TableCell>
        <TableCell id={"regression_measurement_name"} align="left" className={styles.tableCell}>
          {data?.measurement.value || "-"}
        </TableCell>
        <TableCell id={"regression_measurement_flag"} align="left" className={styles.tableCell}>
          {data.flag}
        </TableCell>
        <TableCell id={"regression_measurement_value"} align="left" className={styles.tableCell}>
          {formatMeasurementValue(data?.value)}
        </TableCell>
        <TableCell id={"regression_measurement_reference"} align="right" className={styles.tableCell}>
          {data?.measurement.low_range !== undefined
            ? `${data?.measurement.low_range} - ${data?.measurement.high_range} ${data?.measurement.units}`
            : "-"}
        </TableCell>
      </TableRow>
    </>
  );
};

export const DicomRow = ({ data }: { data: ReportDicom }) => {
  const [open, setOpen] = useState<boolean>(false);
  const format_confidence = (confidence: number | undefined | null) => {
    if (confidence) {
      const formatted_confidence = Math.round(confidence * 100);
      return `(${formatted_confidence}%)`;
    }
    return "";
  };

  const format_perspective = ({ perspective }: { perspective: Perspective | undefined }) => {
    if (perspective && perspective.viewport) {
      return `${perspective.viewport.window} ${perspective.viewport.view}`;
    }
    return "-";
  };

  const format_quality = (quality: Quality | undefined) => {
    if (quality && quality.quantifiability) {
      return `${quality.quantifiability}`;
    }

    return "-";
  };
  return (
    <>
      <TableRow
        key={data.uuid}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        onClick={() => {
          return setOpen((prevState) => !prevState);
        }}
        className={cx({ tableRow: frames?.length })}
      >
        <TableCell className={styles.tableCell} colSpan={1}>
          {
            <IconButton aria-label="expand row" size="small">
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          }
        </TableCell>
        <TableCell id={"name"} align="left" className={styles.tableCell}>
          {data?.name || "-"}
        </TableCell>
        <TableCell id={"viewport"} align="left" className={styles.tableCell}>
          {format_perspective({ perspective: data.perspective })}
        </TableCell>
        <TableCell id={"quality"} align="right" className={styles.tableCell}>
          {format_quality(data.quality)}
        </TableCell>
      </TableRow>
      <TableRow style={{ backgroundColor: "black" }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Box sx={{ marginBottom: 5 }}>
                <Typography align={"center"}>{data && data.name}</Typography>
                <CardMedia key={data.uuid} component={"video"} autoPlay loop src={data.media} />
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export const DiameterMeasurementRow = ({
  data,
  dicoms,
}: {
  data: DiameterConclusion;
  dicoms: ReportDicom[] | undefined;
}) => {
  const [open, setOpen] = useState<boolean>(false);
  let dicom_object: ReportDicom | undefined;
  if (dicoms !== undefined) {
    if (data.dicoms.length > 0) dicom_object = dicoms.find((dicom) => dicom.uuid === data.dicoms[0].uuid);
  }

  const frames = data?.diameters?.map((diameter) => {
    const keypoints = diameter?.keypoint_predictions?.map((keypoint: any) => {
      return [keypoint?.coordinate_x, keypoint?.coordinate_y];
    });

    return (
      <>
        <Typography align={"center"}>{dicom_object && dicom_object.name}</Typography>
        <Canvas
          editable={false}
          pixel_to_cm_delta_x={diameter.frame?.delta_x}
          pixel_to_cm_delta_y={diameter.frame?.delta_y}
          imageFile={diameter?.frame?.file}
          keypoints={keypoints}
          key={`${keypoints}`}
        />
      </>
    );
  });

  return (
    <>
      <TableRow
        key={data.value}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        onClick={() => {
          if (frames?.length) {
            return setOpen((prevState) => !prevState);
          }
          return;
        }}
        className={cx({ tableRow: frames?.length })}
      >
        <TableCell className={styles.tableCell} colSpan={1}>
          {data.dicoms.length > 0 && (
            <IconButton aria-label="expand row" size="small">
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell id={"diameter_measurement_name"} align="left" className={styles.tableCell}>
          {data?.measurement.value || "-"}
        </TableCell>
        <TableCell id={"diameter_measurement_flag"} align="left" className={styles.tableCell}>
          {data.flag}
        </TableCell>
        <TableCell id={"diameter_measurement_value"} align="left" className={styles.tableCell}>
          {formatMeasurementValue(data?.value)}
        </TableCell>
        <TableCell id={"diameter_measurement_reference"} align="right" className={styles.tableCell}>
          {data?.measurement.low_range && data?.measurement.high_range
            ? `${data?.measurement.low_range} - ${data.measurement?.high_range} ${data.measurement?.units}`
            : "-"}
        </TableCell>
      </TableRow>
      <TableRow style={{ backgroundColor: "black" }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                {frames}
              </Typography>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
