import React, { useMemo, useRef, useState, useEffect, FC } from "react";
import { Button } from "@mui/material";
import classNames from "classnames/bind";
import { PolygonAnnotation } from "./polygonAnnotation";
import { Stage, Layer, Image, Text } from "react-konva";
import Cardio from "./assets/cardio.jpg";
import styles from "./segmentation.module.css";

const cx = classNames.bind(styles);

const videoSource = Cardio;
const wrapperStyle = {
  display: "flex",
  justifyContent: "center",
  marginTop: 20,
};

interface canvas {
  imageFile?: string;
  editable?: boolean;
  keypoints?: any;
  pixel_to_cm_delta_x: number | undefined;
  pixel_to_cm_delta_y: number | undefined;
}

export const Canvas: FC<canvas> = ({
  editable = true,
  imageFile,
  keypoints,
  pixel_to_cm_delta_x,
  pixel_to_cm_delta_y,
}) => {
  const element = new window.Image();

  element.src = imageFile || "";
  const [image, setImage] = useState<any>(element);
  const imageRef = useRef<any>(null);
  const stageRef = useRef<any>(null);
  const [points, setPoints] = useState<any>([]);
  const [size, setSize] = useState<any>({});
  const [flattenedPoints, setFlattenedPoints] = useState<any>();
  const [position, setPosition] = useState<any>([0, 0]);
  const [isMouseOverPoint, setMouseOverPoint] = useState<any>(false);
  const [isPolyComplete, setPolyComplete] = useState<any>(false);
  const [option, setOption] = useState<string>("keypointer");
  const [imageReady, setImageReady] = useState<boolean>(false);
  const [stage, setStage] = useState<any>({
    scale: 1,
    x: 0,
    y: 0,
  });
  const videoElement: any = useMemo(() => {
    const element = new window.Image();
    element.src = imageFile || "";
    return element;
    // eslint-disable-next-line
  }, [videoSource]); //it may come from redux so it may be dependency that's why I left it as dependecny...

  useEffect(() => {
    const onload = function () {
      setSize({
        width: videoElement.width,
        height: videoElement.height,
      });
      imageRef.current = videoElement;
    };
    document.addEventListener("load", onload);
    return () => {
      videoElement.removeEventListener("load", onload);
    };
  }, [videoElement]);

  useEffect(() => {
    if (Array.isArray(keypoints)) {
      setPoints(keypoints);
      setPolyComplete(true);
    }
  }, []);

  useEffect(() => {
    setFlattenedPoints(points.concat(isPolyComplete ? [] : position).reduce((a: any, b: any) => a.concat(b), []));
  }, [points, isPolyComplete, position]);

  useEffect(() => {
    const imageStatus = image.addEventListener("load", () => {
      setImageReady(true);
    });
    return () => image.removeEventListener("load", imageStatus);
  }, []);

  const getRelativePointerPosition = (node: any) => {
    // the function will return pointer position relative to the passed node
    const transform = node.getAbsoluteTransform().copy();
    // to detect relative position we need to invert transform
    transform.invert();

    // get pointer (say mouse or touch) position
    const pos = node.getStage().getPointerPosition();

    // now we find a relative point
    return transform.point(pos);
  };

  const handleZoom = (e: any) => {
    if (!editable) {
      return;
    }
    e.evt.preventDefault();
    const scaleBy = 1.02;
    const stage = e.target.getStage();
    const oldScale = stage.scaleX();
    const oldScaleY = stage.scaleY();
    const mousePointTo = {
      x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
      y: stage.getPointerPosition().y / oldScaleY - stage.y() / oldScaleY,
    };
    let newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;
    if (newScale < 1) {
      newScale = 1;
    }
    if (newScale > 2) {
      newScale = 2;
    }
    setStage({
      scale: e.evt.deltaY > 0 ? newScale : 1,
      x: e.evt.deltaY > 0 ? (stage.getPointerPosition().x / newScale - mousePointTo.x) * newScale : 0,
      y: e.evt.deltaY > 0 ? (stage.getPointerPosition().y / newScale - mousePointTo.y) * newScale : 0,
    });
  };

  const getMousePos = (stage: any) => {
    if (!editable) {
      return;
    }
    return [getRelativePointerPosition(stage).x, getRelativePointerPosition(stage).y];
  };
  //drawing begins when mousedown event fires.
  const handleMouseDown = (e: any) => {
    if (!editable) {
      return;
    }
    if (isPolyComplete) return;
    const stage = e.target.getStage();
    const mousePos = getMousePos(stage);
    if (isMouseOverPoint && points.length >= 3) {
      setPolyComplete(true);
    } else {
      setPoints([...points, mousePos]);
    }
  };

  const handleLineDraw = (e: any) => {
    if (!editable) {
      return;
    }
    if (isPolyComplete) return;
    const stage = e.target.getStage();
    const mousePos = getMousePos(stage);
    if (points.length === 1) {
      setPolyComplete(true);
      setPoints([...points, mousePos]);
    } else {
      setPoints([...points, mousePos]);
    }
  };

  const handleMouseMove = (e: any) => {
    if (!editable) {
      return;
    }
    const stage = e.target.getStage();
    const mousePos = getMousePos(stage);
    setPosition(mousePos);
  };
  const handleMouseOverStartPoint = (e: any) => {
    if (!editable) {
      return;
    }
    if (isPolyComplete || points.length < 3) return;
    e.target.scale({ x: 3, y: 3 });
    setMouseOverPoint(true);
  };
  const handleMouseOutStartPoint = (e: any) => {
    if (!editable) {
      return;
    }
    e.target.scale({ x: 1, y: 1 });
    setMouseOverPoint(false);
  };
  const handlePointDragMove = (e: any) => {
    if (!editable) {
      return;
    }
    const stage = e.target.getStage();
    const index = e.target.index - 1;
    const pos = [e.target._lastPos.x, e.target._lastPos.y];
    if (pos[0] < 0) pos[0] = 0;
    if (pos[1] < 0) pos[1] = 0;
    if (pos[0] > stage.width()) pos[0] = stage.width();
    if (pos[1] > stage.height()) pos[1] = stage.height();
    setPoints([...points.slice(0, index), pos, ...points.slice(index + 1)]);
  };

  const undo = () => {
    setPoints(points.slice(0, -1));
    setPolyComplete(false);
    setPosition(points[points.length - 1]);
  };
  const reset = () => {
    setPoints([]);
    setPolyComplete(false);
    setStage({
      scale: 1,
      x: 0,
      y: 0,
    });
  };
  const handleGroupDragEnd = (e: any) => {
    if (!editable) {
      return;
    }
    //drag end listens other children circles' drag end event
    //...that's, why 'name' attr is added, see in polygon annotation part
    if (e.target.name() === "polygon") {
      const result: any = [];
      const copyPoints = [...points];
      copyPoints.map((point) => result.push([point[0] + e.target.x(), point[1] + e.target.y()]));
      e.target.position({ x: 0, y: 0 }); //needs for mouse position otherwise when click undo you will see that mouse click position is not normal:)
      setPoints(result);
    }
  };

  const handleOptionChange = (value: string) => {
    setOption((prevValue: string) => {
      if (prevValue !== value) {
        setPoints([]);
        setPolyComplete(false);
        setStage({
          scale: 1,
          x: 0,
          y: 0,
        });
      }
      return value;
    });
  };

  return (
    <div style={wrapperStyle}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          overflowX: "auto",
        }}
      >
        <Stage
          width={image.width}
          height={image.height}
          onMouseMove={handleMouseMove}
          onMouseDown={option === "segmenter" ? handleMouseDown : handleLineDraw}
          ref={stageRef}
          scaleX={stage.scale}
          scaleY={stage.scale}
          onWheel={handleZoom}
          x={stage.x}
          y={stage.y}
        >
          <Layer>
            <Image ref={imageRef} image={image} x={0} y={0} width={image.width} height={image.height} />
            {imageReady && (
              <PolygonAnnotation
                pixel_to_cm_delta_x={pixel_to_cm_delta_x}
                pixel_to_cm_delta_y={pixel_to_cm_delta_y}
                points={points}
                flattenedPoints={flattenedPoints}
                handlePointDragMove={handlePointDragMove}
                handleGroupDragEnd={handleGroupDragEnd}
                handleMouseOverStartPoint={handleMouseOverStartPoint}
                handleMouseOutStartPoint={handleMouseOutStartPoint}
                isFinished={isPolyComplete}
                option={option}
                editable={editable}
              />
            )}
          </Layer>
        </Stage>
        {editable && (
          <div
            className={styles.canvasControls}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              className={cx("canvasButton", { selectedOption: option === "segmenter" })}
              onClick={() => handleOptionChange("segmenter")}
            >
              Segment
            </Button>
            <Button
              variant="contained"
              className={cx("canvasButton", { selectedOption: option === "keypointer" })}
              onClick={() => handleOptionChange("keypointer")}
            >
              Line
            </Button>
            <Button variant="contained" className={styles.canvasButton} onClick={undo}>
              Undo
            </Button>
            <Button variant="contained" className={styles.canvasButton} onClick={reset}>
              Reset
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
