import { FilePond, registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

export const Uploader = (props: { id: string; onLoad: () => void }) => {
  // specify upload params and url for your files

  return (
    <FilePond
      name={"file"}
      server={{
        url: process.env.REACT_APP_API_URL,
        process: {
          url: "/api/v2/dicoms/",
          method: "POST",
          headers: {
            Authorization: "Bearer " + window.localStorage.getItem("token"),
          },
          onload: (response) => {
            props.onLoad();
            return "";
          },
          ondata: (formData) => {
            formData.append("study", props.id);
            return formData;
          },
        },
      }}
      allowMultiple={true}
      credits={false}
    />
  );
};
