import React from "react";
import FolderOffIcon from "@mui/icons-material/FolderOff";
import styles from "./emptyData.module.css";

export const EmptyData = () => {
  return (
    <div className={styles.wrapper}>
      <FolderOffIcon className={styles.icon} />
      <span className={styles.noDataText}>No data</span>
      <br />
    </div>
  );
};
