import React, { FC, useEffect, useState } from "react";
import { Button, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import classNames from "classnames/bind";
import icardioLogo from "../assets/icardioLogo.svg";
import LoginIcon from "@mui/icons-material/Login";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import styles from "./header.module.css";
import { Sidebar } from "../pages/home/sidebar";

const cx = classNames.bind(styles);

export const Header: FC = () => {
  const [scroll, setScroll] = useState<boolean>(false);
  const [showBurgerMenu, setShowBurgerMenu] = useState<boolean>(false);

  useEffect(() => {
    const scrollEvent: any = document.addEventListener("scroll", () => {
      if (window.scrollY !== 0) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    });
    return () => document.removeEventListener("scroll", scrollEvent);
  }, []);

  useEffect(() => {
    const windowResize: any = window.addEventListener("resize", () => {
      if (window.innerWidth > 992) {
        setShowBurgerMenu(false);
      }
    });
    return () => window.removeEventListener("resize", windowResize);
  }, []);

  const handleMenuOpen = () => {
    setShowBurgerMenu((prevState) => !prevState);
  };

  return (
    <>
      <nav className={cx("mainNav", { menuOnScroll: scroll })}>
        <div className={styles.container}>
          <Grid item xs={4} md={1.5} className={styles.logoWrapper}>
            <Link to="/">
              <img src={icardioLogo} alt="icardioLogo" className={styles.logo} />
            </Link>
          </Grid>
          <Grid item xs={6.5}>
            <ul className={styles.navLinksList}>
              <li className={styles.navLinksListItem}>
                <a href="https://www.icardio.ai/solutions" className={styles.navLink}>
                  Solutions
                </a>
              </li>
              <li className={styles.navLinksListItem}>
                <a href="https://www.icardio.ai/interpretations" className={styles.navLink}>
                  Interpretations
                </a>
              </li>
              <li className={styles.navLinksListItem}>
                <a href="https://www.icardio.ai/data" className={styles.navLink}>
                  Data
                </a>
              </li>
              <li className={styles.navLinksListItem}>
                <a href="https://www.icardio.ai/team" className={styles.navLink}>
                  Team
                </a>
              </li>
              <li className={styles.navLinksListItem}>
                <a href="https://www.icardio.ai/releases" className={styles.navLink}>
                  Releases
                </a>
              </li>
            </ul>
          </Grid>
          <Grid item xs={4} className={styles.contactButtonsWrapper}>
            <div className={styles.desktopScreenButtons}>
              <div className={styles.contactButtonWrapper}>
                <Button variant="outlined" className={styles.contactButton}>
                  Contact us
                </Button>
              </div>
              <Link to="/login">
                <Button variant="outlined" className={styles.loginButton}>
                  <LoginIcon className={styles.loginIcon} /> Log In
                </Button>
              </Link>
            </div>
            <Button className={styles.menuIcon} onClick={handleMenuOpen}>
              {showBurgerMenu ? <CloseIcon /> : <MenuIcon />}
            </Button>
          </Grid>
        </div>
      </nav>
      <Sidebar showBurgerMenu={showBurgerMenu} />
    </>
  );
};
