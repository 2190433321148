import React from "react";
import { Routes, Route } from "react-router-dom";
import { Home } from "./pages/home";
import { Studies } from "./pages/studies";
import { StudiesCreate } from "./pages/studiesCreate";
import { ReportDetails } from "./pages/reportDetails";
import { Login } from "./pages/login";
import { Signup } from "./pages/signup";
import { Webhooks } from "./pages/webhooks";
import { Segmentation } from "./pages/segmentation";
import { PrivateRoute } from "./components/privateRoute";
// import { StudyEditDetails } from "./pages/studyEditDetails";
import { MiniDrawer } from "./components/drawer";
import { AccountDetails } from "./pages/accountDetails";

const withDrawer = (component: any) => {
  return <MiniDrawer>{component}</MiniDrawer>;
};

export const App = () => {
  return (
    <Routes>
      <Route path="/" element={<PrivateRoute component={withDrawer(<Studies />)} />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/studies" element={<PrivateRoute component={withDrawer(<Studies />)} />} />
      <Route path="/studies/:id" element={<PrivateRoute component={withDrawer(<StudiesCreate />)} />} />
      <Route path="/report/:id" element={<PrivateRoute component={withDrawer(<ReportDetails />)} />} />
      {/*<Route path="/studies/edit/:id" element={<StudyEditDetails />} />*/}
      <Route path="/account-details" element={<PrivateRoute component={withDrawer(<AccountDetails />)} />} />
      <Route path="/segmentation/:id" element={<PrivateRoute component={withDrawer(<Segmentation />)} />} />
    </Routes>
  );
};
