import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
// eslint-disable-next-line
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
// eslint-disable-next-line
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import iCardioLogo from "../assets/icardioLogo.svg";
import styles from "./drawer.module.css";
import { Grid } from "@mui/material";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const MiniDrawer = ({ children }: any) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const openPopover = Boolean(anchorEl);
  const id = openPopover ? "simple-popover" : undefined;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    window.localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <div className={styles.headerWrapper}>
          <Toolbar className={styles.toolbar}>
            <div className={styles.logoWrapper}>
              <Link to="/">
                <img src={iCardioLogo} className={styles.logo} />
              </Link>
            </div>
            <div className={styles.mainNavWrapper}>
              <Typography variant="h6" component="div" className={styles.pageName}>
                <Link className={styles.navLink} to="/studies">
                  Dashboard
                </Link>

                <a className={styles.navLink} target={"_blank"} href={process.env.REACT_APP_API_URL + "/docs/"}>
                  Docs
                </a>
              </Typography>
            </div>

            <div className={styles.accountIconWrapper}>
              <Button variant="text" aria-describedby={id} onClick={handlePopoverOpen}>
                <AccountCircleIcon className={styles.accountIcon} />
              </Button>
            </div>
          </Toolbar>
        </div>
      </AppBar>
      <Box component="main" sx={{ flexGrow: 1, p: 3, overflowX: "hidden" }}>
        <DrawerHeader />
        {children}
      </Box>
      <Popover
        id={id}
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <ul className={styles.popoverList}>
          <li>
            <Link to="/account-details" className={styles.navLink} onClick={handleClosePopover}>
              <Typography sx={{ p: 2 }} className={styles.popoverListItem}>
                <ManageAccountsIcon className={styles.logoutIcon} /> My account
              </Typography>
            </Link>
          </li>
          <li>
            <Typography sx={{ p: 2 }} className={styles.popoverListItem} onClick={handleLogout}>
              <LogoutIcon className={styles.logoutIcon} /> Logout
            </Typography>
          </li>
        </ul>
      </Popover>
    </Box>
  );
};
