import { SegmentationData, SegmentationPrediction } from "../../api/generated_api";
import { Group, Image, Layer, Line, Stage } from "react-konva";
import React, { useEffect, useState } from "react";
import { Switch } from "@mui/material";

interface ISegmentation {
  data: SegmentationPrediction;
  image: string;
}

interface ILineDisplay {
  lines: SegmentationData[];
}

export const Segmentation = (props: ISegmentation) => {
  const element = new window.Image();

  element.src = props.image || "";
  const [image, setImage] = useState<any>(element);
  const [imageReady, setImageReady] = useState<boolean>(false);

  useEffect(() => {
    const imageStatus = image.addEventListener("load", () => {
      setImageReady(true);
    });
    return () => image.removeEventListener("load", imageStatus);
  }, []);

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const [checked, setChecked] = React.useState(true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        overflowX: "auto",
      }}
    >
      <Switch checked={checked} onChange={handleChange} inputProps={{ "aria-label": "Display segmentation" }} />
      <Stage width={image.width} height={image.height}>
        <Layer>
          <Image image={image} x={0} y={0} width={image.width} height={image.height} />
        </Layer>
        <Layer>
          {props.data.lines?.map((line, index) => {
            const { type, point_1_coordinate_x, point_1_coordinate_y, point_2_coordinate_x, point_2_coordinate_y } =
              line;

            return (
              checked && (
                <Line
                  key={index}
                  points={[point_1_coordinate_x, point_1_coordinate_y, point_2_coordinate_x, point_2_coordinate_y]}
                  stroke="yellow"
                />
              )
            );
          })}
        </Layer>
      </Stage>
    </div>
  );
};
