import React, { useState, FC } from "react";
import { Line, Circle, Group, Text } from "react-konva";
import { minMax, dragBoundFunc } from "./utils/canvas";
/**
 *
 * @param {minMaxX} props
 * minMaxX[0]=>minX
 * minMaxX[1]=>maxX
 *
 */
export const PolygonAnnotation: FC<any> = ({
  points,
  flattenedPoints,
  isFinished,
  handlePointDragMove,
  handleGroupDragEnd,
  handleMouseOverStartPoint,
  handleMouseOutStartPoint,
  option,
  editable,
  pixel_to_cm_delta_x,
  pixel_to_cm_delta_y,
}) => {
  const vertexRadius = 3;
  const pxToCm = 0.0527577934791144;

  const [stage, setStage] = useState<any>();
  const [minMaxX, setMinMaxX] = useState([0, 0]); //min and max in x axis
  const [minMaxY, setMinMaxY] = useState([0, 0]); //min and max in y axis

  const lineLength = () => {
    if (points.length > 1 && points.every((pointCoordinates: number[]) => pointCoordinates.length === 2)) {
      return Math.hypot(
        (points[1][0] - points[0][0]) * pixel_to_cm_delta_x,
        (points[1][1] - points[0][1]) * pixel_to_cm_delta_y
      );
    } else return 0;
  };

  const getLabelPosition = () => {
    if (points.length > 1 && points.every((pointCoordinates: number[]) => pointCoordinates.length === 2)) {
      return {
        x: points[0][0] + (points[1][0] - points[0][0]) * 0.5 + 5,
        y: points[0][1] + (points[1][1] - points[0][1]) * 0.5 + 5,
      };
    } else return { x: 0, y: 0 };
  };

  const handleGroupMouseOver = (e: any) => {
    if (!isFinished) return;
    e.target.getStage().container().style.cursor = "move";
    setStage(e.target.getStage());
  };
  const handleGroupMouseOut = (e: any) => {
    e.target.getStage().container().style.cursor = "default";
  };

  const handleGroupDragStart = (e: any) => {
    if (!editable) {
      return;
    }
    const arrX = points.map((p: any) => p[0]);
    const arrY = points.map((p: any) => p[1]);
    setMinMaxX(minMax(arrX));
    setMinMaxY(minMax(arrY));
  };
  const groupDragBound = (pos: any) => {
    let { x, y } = pos;
    const sw = stage.width();
    const sh = stage.height();
    if (minMaxY[0] + y < 0) y = -1 * minMaxY[0];
    if (minMaxX[0] + x < 0) x = -1 * minMaxX[0];
    if (minMaxY[1] + y > sh) y = sh - minMaxY[1];
    if (minMaxX[1] + x > sw) x = sw - minMaxX[1];
    return { x, y };
  };
  return (
    <>
      {editable ? (
        <Group
          name="polygon"
          draggable={isFinished}
          onDragStart={handleGroupDragStart}
          onDragEnd={handleGroupDragEnd}
          dragBoundFunc={groupDragBound}
          onMouseOver={handleGroupMouseOver}
          onMouseOut={handleGroupMouseOut}
        >
          <Line
            points={flattenedPoints}
            stroke="#800b08"
            strokeWidth={1.5}
            closed={isFinished}
            fill="rgba(255,0,0,0.4)"
          />
          {points.map((point: any, index: any) => {
            const x = point[0] - vertexRadius / 2;
            const y = point[1] - vertexRadius / 2;
            const startPointAttr =
              index === 0
                ? {
                    hitStrokeWidth: 12,
                    onMouseOver: handleMouseOverStartPoint,
                    onMouseOut: handleMouseOutStartPoint,
                  }
                : null;
            return (
              <Circle
                key={index}
                x={x}
                y={y}
                radius={vertexRadius}
                fill="yellow"
                stroke="yellow"
                strokeWidth={0.5}
                draggable
                onDragMove={handlePointDragMove}
                dragBoundFunc={(pos) => dragBoundFunc(stage.width(), stage.height(), vertexRadius, pos)}
                {...startPointAttr}
              />
            );
          })}
          {option === "keypointer" && points.length >= 2 && (
            <Text
              text={`${lineLength().toFixed(2)} cm`}
              x={getLabelPosition().x}
              y={getLabelPosition().y}
              fontSize={10}
              align="right"
              fill="yellow"
            />
          )}
        </Group>
      ) : (
        <Group name="polygon">
          <Line points={flattenedPoints} stroke="yellow" strokeWidth={1.5} closed={isFinished} fill="yellow" />
          {points.map((point: any, index: any) => {
            const x = point[0] - vertexRadius / 2;
            const y = point[1] - vertexRadius / 2;
            const startPointAttr =
              index === 0
                ? {
                    hitStrokeWidth: 12,
                    onMouseOver: handleMouseOverStartPoint,
                    onMouseOut: handleMouseOutStartPoint,
                  }
                : null;
            return (
              <Circle
                key={index}
                x={x}
                y={y}
                radius={vertexRadius}
                fill="yellow"
                stroke="yellow"
                strokeWidth={0.5}
                {...startPointAttr}
              />
            );
          })}
          {option === "keypointer" && points.length >= 2 && (
            <Text
              text={`${lineLength().toFixed(2)} cm`}
              x={getLabelPosition().x}
              y={getLabelPosition().y}
              fontSize={10}
              align="right"
              fill="yellow"
            />
          )}
        </Group>
      )}
    </>
  );
};
