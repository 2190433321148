import React, { useEffect, useState } from "react";
import { FormControl, Input, Grid, Button } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Webhooks } from "../webhooks";
import styles from "./accountDetails.module.css";
import { apiClient } from "../../api/base";
import { User } from "../../api/generated_api";

export const AccountDetails = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [token, setToken] = useState("");
  const [tokenPresent, setTokenPresent] = useState(false);

  const [user, setUser] = useState<User>({ email: "", first_name: "", last_name: "" });

  const accessToken = window.localStorage.getItem("token");

  const loadToken = () => {
    apiClient.listTokens().then((res) => {
      if (res.data.count === 1) {
        const datatoken = res.data?.results?.at(0)?.key;
        if (datatoken !== undefined) {
          setToken(datatoken);
          setTokenPresent(true);
        }
      } else {
        setTokenPresent(false);
      }
    });
  };

  const handleTokenDelete = () => {
    setToken("");

    apiClient.destroyToken({ id: token }).then((r) => {
      if (r.status === 204) {
        setToken("");
        setTokenPresent(false);
      }
    });
  };

  useEffect(() => {
    loadToken();
  }, []);

  useEffect(() => {
    apiClient.retrieveUser().then((res) => {
      setUser(res.data);
    });
  }, []);

  const createToken = () => {
    apiClient
      .createToken({})
      .then((res) => {
        if (res.data.key !== undefined) {
          setToken(res.data.key);
          setTokenPresent(true);
        }
      })
      .catch((err) => {
        enqueueSnackbar("Token load failed", { variant: "error" });
      });
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setUser((user) => {
      return { ...user, [event.target.id]: event.target.value };
    });
  };

  return (
    <Grid container justifyContent="center" className={styles.container}>
      <form className={styles.form}>
        <FormControl fullWidth={true} variant="standard" className={styles.formControl}>
          <span className={styles.inputLabel}>E-Mail</span>
          <Input id="email" value={user.email} disabled={true} />
        </FormControl>
        {/* 
        <FormControl fullWidth={true} variant="standard" className={styles.formControl}>
          <span className={styles.inputLabel}>First name</span>
          <Input
            id="first_name"
            onChange={handleChange}
            onBlur={handleUserUpdate}
            value={user.first_name}
            readOnly={false}
          />
        </FormControl>

        <FormControl fullWidth={true} variant="standard" className={styles.formControl}>
          <span className={styles.inputLabel}>Last name</span>
          <Input
            onBlur={handleUserUpdate}
            onChange={handleChange}
            id="last_name"
            value={user.last_name}
            readOnly={false}
          />
        </FormControl> 
*/}

        <FormControl fullWidth={true} className={styles.formControl}>
          <span className={styles.inputLabel}>API Token</span>
          <Input
            id="token"
            placeholder="Token"
            value={token}
            disabled={true}
            className={styles.tokenInput}
            endAdornment={
              <>
                {!tokenPresent ? (
                  <Button
                    data-cy={"create-token"}
                    variant="text"
                    className={styles.manageTokenButton}
                    onClick={createToken}
                  >
                    <AddCircleIcon className={styles.deleteIcon} />
                  </Button>
                ) : (
                  <Button
                    data-cy={"delete-token"}
                    variant="text"
                    className={styles.manageTokenButton}
                    onClick={handleTokenDelete}
                  >
                    <DeleteIcon className={styles.deleteIcon} />
                  </Button>
                )}
              </>
            }
          />
        </FormControl>
      </form>
      <Webhooks />
    </Grid>
  );
};
